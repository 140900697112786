import React from "react";
import api from "../../Environment";
import Helper from "../Helper/helper";
import "./LiveTV.css";
import ToastDemo from "../Helper/toaster";
import { translate, t } from "react-multi-lang";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const $ = window.$;

class LiveTvVideo extends Helper {
  state = {
    // loading: true,
    loadingLiveVideos: true,
    liveVideos: null,
  };

  componentDidMount() {
    this.getLiveVideos();
  }

  getLiveVideos = unique_id => {
    let inputData = {
        live_tv_video_id:this.props.match.params.live_tv_video_id,
    };
    api.postMethod("live_tv_videos", inputData)
      .then(response => {
          if (response.data.success === true) {              
              this.setState({
                loadingLiveVideos: false,
                liveVideos: response.data.data
              });
          } else {
          }
      })
      .catch(function (error) { });
  };

  ref = (player) => {
    this.player = player;
  };

  render() {
    const { liveVideos, loadingLiveVideos } = this.state;

    return (
      <div className="main padding-top-md">
        <div className="live-tv-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
              {loadingLiveVideos
                ? t("loading") : liveVideos.default_video ? 
                <div className="live-tv-left-sec">
                  <div className="live-tv-main-img-sec">
                  <ReactPlayer
                    ref={this.ref}
                    url={liveVideos.default_video.hls_video_url}
                    controls={true}
                    width="100%"
                    height="60vh"
                    playing={true}
                    light={liveVideos.default_video.image}
                  />
                  </div>
                  <div className="live-tv-main-info-sec">
                    <h4>{liveVideos.default_video.title}</h4>
                    <p>{renderHTML(liveVideos.default_video.description)}</p>
                  </div>
                </div>
                : "" }
              </div>
              <div className="col-md-5">
                <div className="live-tv-right-sec">
                {loadingLiveVideos
                  ? t("loading") : liveVideos.live_tv_category ?
                  <div className="uploader-details">
                    <div className="uploader-img-sec">
                      <img
                        src={liveVideos.live_tv_category.image}
                        alt={liveVideos.live_tv_category.title}
                        className="user-img"
                      />
                    </div>
                    <div className="uploader-info">
                      <h3>{liveVideos.live_tv_category.title}</h3>
                      <p className="date">{liveVideos.live_tv_category.created}</p>
                      <p>{renderHTML(liveVideos.live_tv_category.description)}</p>
                    </div>
                  </div>
                  : ""}
                </div>
              </div>
            </div>
            <div className="more-video-sec">
              <div className="more-video-header-sec">
                <h2 className="section-main-title">More Videos</h2>
                {/* <div className="more-video-btn-sec">
                  <Link to="#" className="more-video-btn">
                      Show More <i className="fas fa-chevron-right ml-1"></i>
                  </Link>
                </div> */}
              </div>
              <div className="row">
                {loadingLiveVideos
                    ? t("loading") : liveVideos.suggestions.length > 0 ?
                    (liveVideos.suggestions.map(
                      (suggesstion,index) => (
                        <div className="col-md-3">
                          <a href={`/live-tv-video/${suggesstion.live_tv_video_id}`}>
                            <div className="more-video-card">
                              <img
                                src={suggesstion.image}
                                alt={suggesstion.title}
                                className="more-video-img"
                              />
                              <div className="more-video-info">
                                <h3>{suggesstion.title}</h3>
                              </div>
                            </div>
                          </a>
                        </div>
                      )
                    )
                  ) : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default translate(LiveTvVideo);
